@import 'axa-icons-variables';
@font-face {
    font-family: '#{$axa-font-family}';
    src: url('#{$axa-font-path}/#{$axa-font-family}.woff?1uuuo4') format('woff'),
    url('#{$axa-font-path}/#{$axa-font-family}.ttf?1uuuo4') format('truetype'),
    url('#{$axa-font-path}/#{$axa-font-family}.svg?1uuuo4##{$axa-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
}

.axa-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$axa-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.axa-magnifier_glass {
        color: #fff !important;
    }
}

.axa-icon-lg {
    font-size: 70px;
}

.axa-bell2 {
    &:before {
        content: $axa-bell2;
    }
}

.axa-24-7 {
    &:before {
        content: $axa-24-7;
    }
}

.axa-agent_woman {
    &:before {
        content: $axa-agent_woman;
    }
}

.axa-allergy {
    &:before {
        content: $axa-allergy;
    }
}

.axa-amputation {
    &:before {
        content: $axa-amputation;
    }
}

.axa-architect {
    &:before {
        content: $axa-architect;
    }
}

.axa-baby_sitting {
    &:before {
        content: $axa-baby_sitting;
    }
}

.axa-baby {
    &:before {
        content: $axa-baby;
    }
}

.axa-back_pain {
    &:before {
        content: $axa-back_pain;
    }
}

.axa-birthday {
    &:before {
        content: $axa-birthday;
    }
}

.axa-briefcase {
    &:before {
        content: $axa-briefcase;
    }
}

.axa-burger_menu {
    &:before {
        content: $axa-burger_menu;
    }
}

.axa-calculatorB {
    &:before {
        content: $axa-calculatorB;
    }
}

.axa-car {
    &:before {
        content: $axa-car;
    }
}

.axa-chatB {
    &:before {
        content: $axa-chatB;
    }
}

.axa-checkB {
    &:before {
        content: $axa-checkB;
    }
}

.axa-cigarrete {
    &:before {
        content: $axa-cigarrete;
    }
}

.axa-clockB {
    &:before {
        content: $axa-clockB;
    }
}

.axa-concierge_woman {
    &:before {
        content: $axa-concierge_woman;
    }
}

.axa-contractor {
    &:before {
        content: $axa-contractor;
    }
}

.axa-Conversation {
    &:before {
        content: $axa-Conversation;
    }
}

.axa-critical_illness {
    &:before {
        content: $axa-critical_illness;
    }
}

.axa-dangerB {
    &:before {
        content: $axa-dangerB;
    }
}

.axa-dental {
    &:before {
        content: $axa-dental;
    }
}

.axa-doctor_man {
    &:before {
        content: $axa-doctor_man;
    }
}

.axa-doctor_woman {
    &:before {
        content: $axa-doctor_woman;
    }
}

.axa-documentB {
    &:before {
        content: $axa-documentB;
    }
}

.axa-dollar {
    &:before {
        content: $axa-dollar;
    }
}

.axa-downloadB {
    &:before {
        content: $axa-downloadB;
    }
}

.axa-elderly_man {
    &:before {
        content: $axa-elderly_man;
    }
}

.axa-elderly_woman {
    &:before {
        content: $axa-elderly_woman;
    }
}

.axa-emailB {
    &:before {
        content: $axa-emailB;
    }
}

.axa-emergency {
    &:before {
        content: $axa-emergency;
    }
}

.axa-employee_retired {
    &:before {
        content: $axa-employee_retired;
    }
}

.axa-employee_transition {
    &:before {
        content: $axa-employee_transition;
    }
}

.axa-euro {
    &:before {
        content: $axa-euro;
    }
}

.axa-eye {
    &:before {
        content: $axa-eye;
    }
}

.axa-Facebook {
    &:before {
        content: $axa-Facebook;
    }
}

.axa-facebookB {
    &:before {
        content: $axa-facebookB;
    }
}

.axa-family_1 {
    &:before {
        content: $axa-family_1;
    }
}

.axa-family_2 {
    &:before {
        content: $axa-family_2;
    }
}

.axa-family_3 {
    &:before {
        content: $axa-family_3;
    }
}

.axa-family_4 {
    &:before {
        content: $axa-family_4;
    }
}

.axa-family_5 {
    &:before {
        content: $axa-family_5;
    }
}

.axa-family_6 {
    &:before {
        content: $axa-family_6;
    }
}

.axa-family {
    &:before {
        content: $axa-family;
    }
}

.axa-fidelityB {
    &:before {
        content: $axa-fidelityB;
    }
}

.axa-foreman {
    &:before {
        content: $axa-foreman;
    }
}

.axa-gearB {
    &:before {
        content: $axa-gearB;
    }
}

.axa-gender {
    &:before {
        content: $axa-gender;
    }
}

.axa-graduation_hat {
    &:before {
        content: $axa-graduation_hat;
    }
}

.axa-growth {
    &:before {
        content: $axa-growth;
    }
}

.axa-hand_cross {
    &:before {
        content: $axa-hand_cross;
    }
}

.axa-hand_heart {
    &:before {
        content: $axa-hand_heart;
    }
}

.axa-health_kit {
    &:before {
        content: $axa-health_kit;
    }
}

.axa-health_kitB {
    &:before {
        content: $axa-health_kitB;
    }
}

.axa-hearing_impaired {
    &:before {
        content: $axa-hearing_impaired;
    }
}

.axa-heart {
    &:before {
        content: $axa-heart;
    }
}

.axa-heartB {
    &:before {
        content: $axa-heartB;
    }
}

.axa-helpB {
    &:before {
        content: $axa-helpB;
    }
}

.axa-hospital_bed {
    &:before {
        content: $axa-hospital_bed;
    }
}

.axa-hospital {
    &:before {
        content: $axa-hospital;
    }
}

.axa-house {
    &:before {
        content: $axa-house;
    }
}

.axa-icard {
    &:before {
        content: $axa-icard;
    }
}

.axa-icon_01 {
    &:before {
        content: $axa-icon_01;
    }
}

.axa-Instagram {
    &:before {
        content: $axa-Instagram;
    }
}

.axa-instagramB {
    &:before {
        content: $axa-instagramB;
    }
}

.axa-kid {
    &:before {
        content: $axa-kid;
    }
}

.axa-LinkedIn {
    &:before {
        content: $axa-LinkedIn;
    }
}

.axa-linkedinB {
    &:before {
        content: $axa-linkedinB;
    }
}

.axa-login_logoutB {
    &:before {
        content: $axa-login_logoutB;
    }
}

.axa-magnifier_glassB {
    &:before {
        content: $axa-magnifier_glassB;
    }
}

.axa-man_employee_hire {
    &:before {
        content: $axa-man_employee_hire;
    }
}

.axa-man_woman {
    &:before {
        content: $axa-man_woman;
    }
}

.axa-man {
    &:before {
        content: $axa-man;
    }
}

.axa-manB {
    &:before {
        content: $axa-manB;
    }
}

.axa-medical_assistance {
    &:before {
        content: $axa-medical_assistance;
    }
}

.axa-mental_disability {
    &:before {
        content: $axa-mental_disability;
    }
}

.axa-mindset {
    &:before {
        content: $axa-mindset;
    }
}

.axa-mobileB {
    &:before {
        content: $axa-mobileB;
    }
}

.axa-motor_disability {
    &:before {
        content: $axa-motor_disability;
    }
}

.axa-newspaperB {
    &:before {
        content: $axa-newspaperB;
    }
}

.axa-ocular_disability {
    &:before {
        content: $axa-ocular_disability;
    }
}

.axa-optical {
    &:before {
        content: $axa-optical;
    }
}

.axa-Padlock {
    &:before {
        content: $axa-Padlock;
    }
}

.axa-padlockB {
    &:before {
        content: $axa-padlockB;
    }
}

.axa-phoneB {
    &:before {
        content: $axa-phoneB;
    }
}

.axa-pin_locatorB {
    &:before {
        content: $axa-pin_locatorB;
    }
}

.axa-plaster_crutch {
    &:before {
        content: $axa-plaster_crutch;
    }
}

.axa-playB {
    &:before {
        content: $axa-playB;
    }
}

.axa-plusB {
    &:before {
        content: $axa-plusB;
    }
}

.axa-pound {
    &:before {
        content: $axa-pound;
    }
}

.axa-printB {
    &:before {
        content: $axa-printB;
    }
}

.axa-psychological_disability {
    &:before {
        content: $axa-psychological_disability;
    }
}

.axa-question_markB {
    &:before {
        content: $axa-question_markB;
    }
}

.axa-serviceB {
    &:before {
        content: $axa-serviceB;
    }
}

.axa-shareB {
    &:before {
        content: $axa-shareB;
    }
}

.axa-stethoscope {
    &:before {
        content: $axa-stethoscope;
    }
}

.axa-toolsB {
    &:before {
        content: $axa-toolsB;
    }
}

.axa-Twitter {
    &:before {
        content: $axa-Twitter;
    }
}

.axa-twitterB {
    &:before {
        content: $axa-twitterB;
    }
}

.axa-uploadB {
    &:before {
        content: $axa-uploadB;
    }
}

.axa-we_chat {
    &:before {
        content: $axa-we_chat;
    }
}

.axa-wheelchair {
    &:before {
        content: $axa-wheelchair;
    }
}

.axa-woman {
    &:before {
        content: $axa-woman;
    }
}

.axa-yen {
    &:before {
        content: $axa-yen;
    }
}

.axa-Youtube {
    &:before {
        content: $axa-Youtube;
    }
}

.axa-youtubeB {
    &:before {
        content: $axa-youtubeB;
    }
}

.axa-24_7 {
    &:before {
        content: $axa-24_7;
    }
}

.axa-breakpoints {
    &:before {
        content: $axa-breakpoints;
    }
}

.axa-calculator {
    &:before {
        content: $axa-calculator;
    }
}

.axa-calendar {
    &:before {
        content: $axa-calendar;
    }
}

.axa-cauge {
    &:before {
        content: $axa-cauge;
    }
}

.axa-chat_man {
    &:before {
        content: $axa-chat_man;
    }
}

.axa-chat_woman {
    &:before {
        content: $axa-chat_woman;
    }
}

.axa-chat {
    &:before {
        content: $axa-chat;
    }
}

.axa-check {
    &:before {
        content: $axa-check;
    }
}

.axa-clock {
    &:before {
        content: $axa-clock;
    }
}

.axa-danger {
    &:before {
        content: $axa-danger;
    }
}

.axa-desktop {
    &:before {
        content: $axa-desktop;
    }
}

.axa-document {
    &:before {
        content: $axa-document;
    }
}

.axa-download {
    &:before {
        content: $axa-download;
    }
}

.axa-email {
    &:before {
        content: $axa-email;
    }
}

.axa-facebook {
    &:before {
        content: $axa-facebook;
    }
}

.axa-fidelity {
    &:before {
        content: $axa-fidelity;
    }
}

.axa-gear {
    &:before {
        content: $axa-gear;
    }
}

.axa-help_man {
    &:before {
        content: $axa-help_man;
    }
}

.axa-help_woman {
    &:before {
        content: $axa-help_woman;
    }
}

.axa-help {
    &:before {
        content: $axa-help;
    }
}

.axa-inbox {
    &:before {
        content: $axa-inbox;
    }
}

.axa-instagram {
    &:before {
        content: $axa-instagram;
    }
}

.axa-linkedin {
    &:before {
        content: $axa-linkedin;
    }
}

.axa-login_logout {
    &:before {
        content: $axa-login_logout;
    }
}

.axa-magnifier_glass {
    &:before {
        content: $axa-magnifier_glass;
    }
}

.axa-medal_1st {
    &:before {
        content: $axa-medal_1st;
    }
}

.axa-mobile {
    &:before {
        content: $axa-mobile;
    }
}

.axa-modular_offer {
    &:before {
        content: $axa-modular_offer;
    }
}

.axa-more {
    &:before {
        content: $axa-more;
    }
}

.axa-newspaper {
    &:before {
        content: $axa-newspaper;
    }
}

.axa-padlock {
    &:before {
        content: $axa-padlock;
    }
}

.axa-phone {
    &:before {
        content: $axa-phone;
    }
}

.axa-pin_locator_map {
    &:before {
        content: $axa-pin_locator_map;
    }
}

.axa-pin_locator {
    &:before {
        content: $axa-pin_locator;
    }
}

.axa-play {
    &:before {
        content: $axa-play;
    }
}

.axa-plus {
    &:before {
        content: $axa-plus;
    }
}

.axa-print {
    &:before {
        content: $axa-print;
    }
}

.axa-question_mark {
    &:before {
        content: $axa-question_mark;
    }
}

.axa-real_time {
    &:before {
        content: $axa-real_time;
    }
}

.axa-service {
    &:before {
        content: $axa-service;
    }
}

.axa-share {
    &:before {
        content: $axa-share;
    }
}

.axa-tablet {
    &:before {
        content: $axa-tablet;
    }
}

.axa-tools {
    &:before {
        content: $axa-tools;
    }
}

.axa-twitter {
    &:before {
        content: $axa-twitter;
    }
}

.axa-upload {
    &:before {
        content: $axa-upload;
    }
}

.axa-wechat {
    &:before {
        content: $axa-wechat;
    }
}

.axa-youtube {
    &:before {
        content: $axa-youtube;
    }
}

.axa-tag_discount {
    &:before {
        content: $axa-tag_discount;
    }
}

.axa-tag_free {
    &:before {
        content: $axa-tag_free;
    }
}

.axa-CAM {
    &:before {
        content: $axa-CAM;
    }
}

.axa-curp {
    &:before {
        content: $axa-curp;
    }
}

.axa-pin_locatorC {
    &:before {
        content: $axa-pin_locatorC;
    }
}

.axa-cross {
    &:before {
        content: $axa-cross;
    }
}

.axa-bell {
    &:before {
        content: $axa-bell;
    }
}

.axa-calendar_filled {
    &:before {
        content: $axa-calendar_filled;
    }
}

.axa-filter {
    &:before {
        content: $axa-filter;
    }
}

.axa-pencil {
    &:before {
        content: $axa-pencil;
    }
}

.axa-star {
    &:before {
        content: $axa-star;
    }
}

.axa-support {
    &:before {
        content: $axa-support;
    }
}

.axa-arrow_top {
    &:before {
        content: $axa-top-arrow;
    }
}

.axa-add_circle {
    &:before {
        content: $axa-add_circle;
    }
}

.axa-close_circle {
    &:before {
        content: $axa-close_circle;
    }
}

.axa-delete_circle {
    &:before {
        content: $axa-delete_circle;
    }
}

.axa-Youtube_Filled {
    &:before {
        content: $axa-Youtube_Cerulean;
    }
}

.axa-Twitter_Filled {
    &:before {
        content: $axa-Twitter_Cerulean;
    }
}

.axa-LinkedIn_Filled {
    &:before {
        content: $axa-LinkedIn_Cerulean;
    }
}

.axa-Instagram_Filled {
    &:before {
        content: $axa-Instagram_Cerulean;
    }
}

.axa-Facebook_Filled {
    &:before {
        content: $axa-Facebook_Cerulean;
    }
}

.axa-drag {
    &:before {
        content: $axa-drag;
    }
}

.axa-female {
    &:before {
        content: $axa-female;
    }
}

.axa-male {
    &:before {
        content: $axa-male;
    }
}

.axa-arrow_bar {
    &:before {
        content: $axa-arrow_bar;
    }
}

.axa-Home {
    &:before {
        content: $axa-Home;
    }
}

.axa-shopping_Cart {
    &:before {
        content: $axa-shopping_Cart;
    }
}

.axa-facebook_filled {
    &:before {
        content: $axa-facebook_filled;
    }
}

.axa-envelope_rounded {
    &:before {
        content: $axa-envelope_rounded;
    }
}

.axa-pin_locator_map_Filled {
    &:before {
        content: $axa-pin_locator_map_Filled;
    }
}

.axa-volver {
    &:before {
        content: $axa-volver;
    }
}

.axa-prescripcion {
    &:before {
        content: $axa-prescripcion;
    }
}

.axa-TDC {
    &:before {
        content: $axa-TDC;
    }
}

.axa-card {
    &:before {
        content: $axa-card;
    }
}

.axa-hand {
    &:before {
        content: $axa-hand;
    }
}

.axa-CAM-Outlined {
    &:before {
        content: $axa-CAM-Outlined;
    }
}

.axa-folder {
    &:before {
        content: $axa-folder;
    }
}

.axa-leave {
    &:before {
        content: $axa-leave;
    }
}

.axa-lab {
    &:before {
        content: $axa-lab;
    }
}

.axa-microscope .path1 {
    &:before {
        content: $axa-microscope-bg;
        color: rgb(22, 63, 116);
    }
}

.axa-microscope .path2 {
    &:before {
        content: $axa-microscope;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}