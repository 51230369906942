/* Colors Style Sheet*/

/* Primary Colors */
$ak-blue: #597a8b;
$ak-red: #da4b4f;
$ak-cerulean: #4f9aaf;
$ak-gray: #2b3d43;

/* Secondary Colors */
$ak-dark_purple: #535486;
$ak-mustard: #e6a547;
$ak-deep_blue: #1b4677;
$ak-light_purple: #5770be;
$ak-aquamarine: #177172;
$ak-green_grass: #679a47;
$gray-700: #333333;
$gray-100: #fafafa;
/* Basic Colors */
$white: #ffffff;
$red: #ff1721;
$blue: #00008f;
$black: #000000;

/* Text Colors */
$ak-blue3: #00467c;
$ak-mustard2: #f4a22a;
$ak-dark_gray: #253E44;
$ak-red2: #ef3b49;

/* button Colors */
$ak-purple: #5970b5;
$ak-blue4: #00477f;
$ak-light_gray: #bebebe;

/** Color palette */

/** AXA */

// PRIMARY COLORS
$white: #ffffff;
$black: #000000;
$red: #ff1721;
$blue: #00008f;
// Blue
$blue-100: #494df4;
$blue-200: #3b3fd8;
$blue-300: #3032c1;
$blue-400: #2425aa;
$blue-500: #00005b;
// Grey
$gray-100: #fafafa;
$gray-200: #f5f5f5;
$gray-300: #e5e5e5;
$gray-400: #d4d4d4;
$gray-500: #999999;
$gray-600: #7f7f7f;
$gray-700: #5f5f5f;
$gray-800: #333333;

// Red
$red-100: #f07662;
$red-200: #ec4d33;
// Light
$axa-igloo: #b5d0ee;
$axa-cotton_candy: #fad6de;
$axa-aqua_green: #9fd9b4;
$axa-acid: #f0ff93;
$axa-dune: #fcd385;
// Dark
$axa-greyjoy: #9fbeaf;
$axa-viridian_grey: #668980;
$axa-pacific: #00adc6;
$axa-teal: #027180;
$axa-azalea: #f1afc6;
$axa-logan: #9190ac;
$axa-apache: #ddbe65;
$axa-tosca: #914146;
// Status
$axa-malachite: #1cc54e;
$axa-sky_tomato: #c91432;

/** KERALTY */

// PRIMARY COLORS
$keralty-red: #da4b4f;
$keralty-blue: #597a8c;
$keralty-blueish: #6e96a8;
$keralty-cerulean: #4f9aaf;
$keralty-gray: #2b3d43;
// SECONDARY COLORS
$keralty-dark_purple: #535486;
$keralty-light_purple: #5770be;
$keralty-mustard: #e6a547;
$keralty-deep_blue: #1b4677;
$keralty-aquamarine: #177172;
$keralty-green_grass: #679a47;