$keralty-font-family: 'KERALTY' !default;
$keralty-font-path: '/assets/fonts' !default;

$keralty-validar: "\e943";
$keralty-poliza: "\e941";
$keralty-libro: "\e942";
$keralty-cancer: "\e936";
$keralty-guts: "\e937";
$keralty-line_3: "\e938";
$keralty-bottle: "\e939";
$keralty-condom: "\e93a";
$keralty-devastado: "\e93b";
$keralty-diabetes: "\e93c";
$keralty-drop: "\e93d";
$keralty-electro: "\e93e";
$keralty-jeringa: "\e93f";
$keralty-ring: "\e940";
$keralty-documentation: "\e935";
$keralty-award: "\e931";
$keralty-microscope_3: "\e932";
$keralty-telephone: "\e933";
$keralty-therapy: "\e934";
$keralty-eat: "\e92a";
$keralty-exercise: "\e92b";
$keralty-lifestyle: "\e92c";
$keralty-sleep: "\e92d";
$keralty-smoke: "\e92e";
$keralty-stress: "\e92f";
$keralty-weight: "\e930";
$keralty-Thumbs: '\e929';
$keralty-companion: '\e927';
$keralty-shield: '\e928';
$keralty-badge: '\e900';
$keralty-building: '\e901';
$keralty-car_1: '\e902';
$keralty-car_2: '\e903';
$keralty-charts: '\e904';
$keralty-concierge: '\e905';
$keralty-doc_0: '\e906';
$keralty-doc_1: '\e907';
$keralty-doc: '\e908';
$keralty-eco: '\e909';
$keralty-first: '\e90a';
$keralty-hands: '\e90b';
$keralty-heart: '\e90c';
$keralty-hearts: '\e90d';
$keralty-hospital: '\e90e';
$keralty-location: '\e90f';
$keralty-lugage: '\e910';
$keralty-lungs: '\e911';
$keralty-medicine: '\e912';
$keralty-microscope_1: '\e913';
$keralty-microscope: '\e914';
$keralty-needle_1: '\e915';
$keralty-needle: '\e916';
$keralty-nurse: '\e917';
$keralty-piggy_bank: '\e918';
$keralty-pill_hand: '\e919';
$keralty-pill: '\e91a';
$keralty-pills_1: '\e91b';
$keralty-pills: '\e91c';
$keralty-return: '\e91d';
$keralty-stetoscope_1: '\e91e';
$keralty-stetoscope: '\e91f';
$keralty-store: '\e920';
$keralty-tags: '\e921';
$keralty-temperature: '\e922';
$keralty-termometer: '\e923';
$keralty-truck: '\e924';
$keralty-umbrella: '\e925';
$keralty-wheelchair: '\e926';
