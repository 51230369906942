$axa-font-family: 'AXA' !default;
$axa-font-path: '/assets/fonts' !default;

$axa-24-7: "\e900";
$axa-breakpoints: "\e901";
$axa-calculator: "\e902";
$axa-calendar: "\e903";
$axa-cauge: "\e904";
$axa-chat_man: "\e905";
$axa-chat_woman: "\e906";
$axa-chat: "\e907";
$axa-check: "\e908";
$axa-clock: "\e909";
$axa-danger: "\e90a";
$axa-desktop: "\e90b";
$axa-document: "\e90c";
$axa-download: "\e90d";
$axa-email: "\e90e";
$axa-facebook: "\e90f";
$axa-fidelity: "\e910";
$axa-gear: "\e911";
$axa-help_man: "\e912";
$axa-help_woman: "\e913";
$axa-help: "\e914";
$axa-inbox: "\e915";
$axa-instagram: "\e916";
$axa-linkedin: "\e917";
$axa-login_logout: "\e918";
$axa-magnifier_glass: "\e919";
$axa-medal_1st: "\e91a";
$axa-mobile: "\e91b";
$axa-modular_offer: "\e91c";
$axa-more: "\e91d";
$axa-newspaper: "\e91e";
$axa-padlock: "\e91f";
$axa-phone: "\e920";
$axa-pin_locator_map: "\e921";
$axa-pin_locator: "\e922";
$axa-play: "\e923";
$axa-plus: "\e924";
$axa-print: "\e925";
$axa-question_mark: "\e926";
$axa-real_time: "\e927";
$axa-service: "\e928";
$axa-share: "\e929";
$axa-tablet: "\e92a";
$axa-tools: "\e92b";
$axa-twitter: "\e92c";
$axa-upload: "\e92d";
$axa-wechat: "\e92e";
$axa-youtube: "\e92f";
$axa-uniE930: "\e930";
$axa-agent_woman: "\e931";
$axa-allergy: "\e932";
$axa-amputation: "\e933";
$axa-architect: "\e934";
$axa-baby_sitting: "\e935";
$axa-baby: "\e936";
$axa-back_pain: "\e937";
$axa-birthday: "\e938";
$axa-briefcase: "\e939";
$axa-burger_menu: "\e93a";
$axa-calculatorB: "\e93b";
$axa-car: "\e93c";
$axa-chatB: "\e93d";
$axa-checkB: "\e93e";
$axa-cigarrete: "\e93f";
$axa-clockB: "\e940";
$axa-concierge_woman: "\e941";
$axa-contractor: "\e942";
$axa-Conversation: "\e943";
$axa-critical_illness: "\e944";
$axa-dangerB: "\e945";
$axa-dental: "\e946";
$axa-doctor_man: "\e947";
$axa-doctor_woman: "\e948";
$axa-documentB: "\e949";
$axa-dollar: "\e94a";
$axa-downloadB: "\e94b";
$axa-elderly_man: "\e94c";
$axa-elderly_woman: "\e94d";
$axa-emailB: "\e94e";
$axa-emergency: "\e94f";
$axa-employee_retired: "\e950";
$axa-employee_transition: "\e951";
$axa-euro: "\e952";
$axa-eye: "\e953";
$axa-Facebook: "\e954";
$axa-facebookB: "\e955";
$axa-family_1: "\e956";
$axa-family_2: "\e957";
$axa-family_3: "\e958";
$axa-family_4: "\e959";
$axa-family_5: "\e95a";
$axa-family_6: "\e95b";
$axa-family: "\e95c";
$axa-fidelityB: "\e95d";
$axa-foreman: "\e95e";
$axa-gearB: "\e95f";
$axa-gender: "\e960";
$axa-graduation_hat: "\e961";
$axa-growth: "\e962";
$axa-hand_cross: "\e963";
$axa-hand_heart: "\e964";
$axa-health_kit: "\e965";
$axa-health_kitB: "\e966";
$axa-hearing_impaired: "\e967";
$axa-heart: "\e968";
$axa-heartB: "\e969";
$axa-helpB: "\e96a";
$axa-hospital_bed: "\e96b";
$axa-hospital: "\e96c";
$axa-house: "\e96d";
$axa-icard: "\e96e";
$axa-icon_01: "\e96f";
$axa-Instagram: "\e970";
$axa-instagramB: "\e971";
$axa-kid: "\e972";
$axa-LinkedIn: "\e973";
$axa-linkedinB: "\e974";
$axa-login_logoutB: "\e975";
$axa-magnifier_glassB: "\e976";
$axa-man_employee_hire: "\e977";
$axa-man_woman: "\e978";
$axa-man: "\e979";
$axa-manB: "\e97a";
$axa-medical_assistance: "\e97b";
$axa-mental_disability: "\e97c";
$axa-mindset: "\e97d";
$axa-mobileB: "\e97e";
$axa-motor_disability: "\e97f";
$axa-newspaperB: "\e980";
$axa-ocular_disability: "\e981";
$axa-optical: "\e982";
$axa-Padlock: "\e983";
$axa-padlockB: "\e984";
$axa-phoneB: "\e985";
$axa-pin_locatorB: "\e986";
$axa-plaster_crutch: "\e987";
$axa-playB: "\e988";
$axa-plusB: "\e989";
$axa-pound: "\e98a";
$axa-printB: "\e98b";
$axa-psychological_disability: "\e98c";
$axa-question_markB: "\e98d";
$axa-serviceB: "\e98e";
$axa-shareB: "\e98f";
$axa-stethoscope: "\e990";
$axa-toolsB: "\e991";
$axa-Twitter: "\e992";
$axa-twitterB: "\e993";
$axa-uploadB: "\e994";
$axa-we_chat: "\e995";
$axa-wheelchair: "\e996";
$axa-woman: "\e997";
$axa-yen: "\e998";
$axa-Youtube: "\e999";
$axa-youtubeB: "\e99a";
$axa-tag_discount: "\e99b";
$axa-tag_free: "\e99c";
$axa-CAM: "\e99d";
$axa-curp: "\e99e";
$axa-pin_locatorC: "\e99f";
$axa-cross: "\e9a0";
$axa-bell: "\e9a1";
$axa-calendar_filled: "\e9a2";
$axa-filter: "\e9a3";
$axa-pencil: "\e9a4";
$axa-star: "\e9a5";
$axa-support: "\e9a6";
$axa-top-arrow: "\e9a7";
$axa-add_circle: "\e9a8";
$axa-Youtube_Cerulean: "\e9a9";
$axa-close_circle: "\e9aa";
$axa-Twitter_Cerulean: "\e9ab";
$axa-delete_circle: "\e9ac";
$axa-LinkedIn_Cerulean: "\e9ad";
$axa-Instagram_Cerulean: "\e9ae";
$axa-Facebook_Cerulean: "\e9af";
$axa-drag: "\e9b0";
$axa-arrow_bar: "\e9b1";
$axa-female: "\e9b2";
$axa-male: "\e9b3";
$axa-pin_locator_map_Filled: "\e9b4";
$axa-Home: "\e9b5";
$axa-card: "\e9b6";
$axa-prescripcion: "\e9b7";
$axa-TDC: "\e9b8";
$axa-volver: "\e9b9";
$axa-hand: "\e9ba";
$axa-CAM-Outlined: "\e9bb";
$axa-bell2: "\e9bc";
$axa-Manita: "\e9bd";
$axa-folder: "\e9be";
$axa-leave: "\e9bf";
$axa-facebook_filled: "\e9f6";
$axa-envelope_rounded: "\e9f7";
$axa-shopping_Cart1: "\e9f8";
$axa-shopping_Cart: "\e9f9";
$axa-lab:"\e9c0";
$axa-microscope: "\e9c2";
$axa-microscope-bg: "\e9c1";