@import "planmed-font-variables";

@font-face {
  font-family: '#{$planmed-font-family}';
  src:
    url('#{$planmed-font-path}/#{$planmed-font-family}.woff?cg1mbs') format('woff'),
    url('#{$planmed-font-path}/#{$planmed-font-family}.ttf?cg1mbs') format('truetype'),
    url('#{$planmed-font-path}/#{$planmed-font-family}.svg?cg1mbs##{$planmed-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.planmed {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$planmed-font-family}', sans-serif  !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Planmed {
  &:before {
    content: $Planmed;
  }
  position: relative;
}

.Registered {
  &:before {
    content: $Registered;
  }
  font-size: 12px;
  position: absolute;
  top: 0;
}
.text-bottom {
  color: #597A8B;
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 14.4px;
  text-align: center;
}
.Registered {
  font-size: 9px;
}
