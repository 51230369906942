@import 'keralty-icons-variables';

@font-face {
  font-family: '#{$keralty-font-family}';
  src: url('#{$keralty-font-path}/#{$keralty-font-family}.woff?gpe5rv') format('woff'),
    url('#{$keralty-font-path}/#{$keralty-font-family}.ttf?gpe5rv') format('truetype'),
    url('#{$keralty-font-path}/#{$keralty-font-family}.svg?gpe5rv##{$keralty-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.keralty-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$keralty-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.keralty-validar {
  &:before {
    content: $keralty-validar;
  }
}
.keralty-poliza {
  &:before {
    content: $keralty-poliza;
  }
}
.keralty-libro {
  &:before {
    content: $keralty-libro;
  }
}
.keralty-cancer {
  &:before {
    content: $keralty-cancer;
  }
}
.keralty-guts {
  &:before {
    content: $keralty-guts;
  }
}
.keralty-line_3 {
  &:before {
    content: $keralty-line_3; 
  }
}
.keralty-bottle {
  &:before {
    content: $keralty-bottle;
  }
}
.keralty-condom {
  &:before {
    content: $keralty-condom;
  }
}
.keralty-devastado {
  &:before {
    content: $keralty-devastado;
  }
}
.keralty-diabetes {
  &:before {
    content: $keralty-diabetes;
  }
}
.keralty-drop {
  &:before {
    content: $keralty-drop;
  }
}
.keralty-electro {
  &:before {
    content: $keralty-electro;
  }
}
.keralty-jeringa {
  &:before {
    content: $keralty-jeringa;
  }
}
.keralty-ring {
  &:before {
    content: $keralty-ring;
  }
}
.keralty-documentation {
  &:before {
    content: $keralty-documentation;
  }
}
.keralty-award {
  &:before {
    content: $keralty-award;
  }
}
.keralty-microscope_3 {
  &:before {
    content: $keralty-microscope_3;
  }
}
.keralty-telephone {
  &:before {
    content: $keralty-telephone;
  }
}
.keralty-therapy {
  &:before {
    content: $keralty-therapy;
  }
}
.keralty-eat {
  &:before {
    content: $keralty-eat;
  }
}
.keralty-exercise {
  &:before {
    content: $keralty-exercise;
  }
}
.keralty-lifestyle {
  &:before {
    content: $keralty-lifestyle;
  }
}
.keralty-sleep {
  &:before {
    content: $keralty-sleep;
  }
}
.keralty-smoke {
  &:before {
    content: $keralty-smoke;
  }
}
.keralty-stress {
  &:before {
    content: $keralty-stress;
  }
}
.keralty-weight {
  &:before {
    content: $keralty-weight;
  }
}
.keralty-Thumbs {
  &:before {
    content: $keralty-Thumbs;
  }
}
.keralty-companion {
  &:before {
    content: $keralty-companion;
  }
}
.keralty-shield {
  &:before {
    content: $keralty-shield;
  }
}
.keralty-badge {
  &:before {
    content: $keralty-badge;
  }
}
.keralty-building {
  &:before {
    content: $keralty-building;
  }
}
.keralty-car_1 {
  &:before {
    content: $keralty-car_1;
  }
}
.keralty-car_2 {
  &:before {
    content: $keralty-car_2;
  }
}
.keralty-charts {
  &:before {
    content: $keralty-charts;
  }
}
.keralty-concierge {
  &:before {
    content: $keralty-concierge;
  }
}
.keralty-doc_0 {
  &:before {
    content: $keralty-doc_0;
  }
}
.keralty-doc_1 {
  &:before {
    content: $keralty-doc_1;
  }
}
.keralty-doc {
  &:before {
    content: $keralty-doc;
  }
}
.keralty-eco {
  &:before {
    content: $keralty-eco;
  }
}
.keralty-first {
  &:before {
    content: $keralty-first;
  }
}
.keralty-hands {
  &:before {
    content: $keralty-hands;
  }
}
.keralty-heart {
  &:before {
    content: $keralty-heart;
  }
}
.keralty-hearts {
  &:before {
    content: $keralty-hearts;
  }
}
.keralty-hospital {
  &:before {
    content: $keralty-hospital;
  }
}
.keralty-location {
  &:before {
    content: $keralty-location;
  }
}
.keralty-lugage {
  &:before {
    content: $keralty-lugage;
  }
}
.keralty-lungs {
  &:before {
    content: $keralty-lungs;
  }
}
.keralty-medicine {
  &:before {
    content: $keralty-medicine;
  }
}
.keralty-microscope_1 {
  &:before {
    content: $keralty-microscope_1;
  }
}
.keralty-microscope {
  &:before {
    content: $keralty-microscope;
  }
}
.keralty-needle_1 {
  &:before {
    content: $keralty-needle_1;
  }
}
.keralty-needle {
  &:before {
    content: $keralty-needle;
  }
}
.keralty-nurse {
  &:before {
    content: $keralty-nurse;
  }
}
.keralty-piggy_bank {
  &:before {
    content: $keralty-piggy_bank;
  }
}
.keralty-pill_hand {
  &:before {
    content: $keralty-pill_hand;
  }
}
.keralty-pill {
  &:before {
    content: $keralty-pill;
  }
}
.keralty-pills_1 {
  &:before {
    content: $keralty-pills_1;
  }
}
.keralty-pills {
  &:before {
    content: $keralty-pills;
  }
}
.keralty-return {
  &:before {
    content: $keralty-return;
  }
}
.keralty-stetoscope_1 {
  &:before {
    content: $keralty-stetoscope_1;
  }
}
.keralty-stetoscope {
  &:before {
    content: $keralty-stetoscope;
  }
}
.keralty-store {
  &:before {
    content: $keralty-store;
  }
}
.keralty-tags {
  &:before {
    content: $keralty-tags;
  }
}
.keralty-temperature {
  &:before {
    content: $keralty-temperature;
  }
}
.keralty-termometer {
  &:before {
    content: $keralty-termometer;
  }
}
.keralty-truck {
  &:before {
    content: $keralty-truck;
  }
}
.keralty-umbrella {
  &:before {
    content: $keralty-umbrella;
  }
}
.keralty-wheelchair {
  &:before {
    content: $keralty-wheelchair;
  }
}
