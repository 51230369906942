@import url('//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,900&display=swap');
@import 'app/styles/icons';
@import 'app/styles/planmed-font';
@import 'app/styles/colors';
@import 'app/styles/axa-icons';
@import 'app/styles/keralty-icons';
@import 'app/styles/planmed-font';
@import 'app/styles/breakpoints';
@import 'app/styles/home-icons';

body,
html {
    font-family: "SourceSansPro", sans-serif !important;
}

@media (max-width: 430px) {
    .active-img {
        width: 45px;
    }

    .img-inactive {
        width: 35px !important;
        height: 35px !important;
    }

    .dotted-line {
        height: 3px !important;
    }

    .text-header {
        font-size: 10px !important;
    }
}

/* Ocultar reCaptcha */
.grecaptcha-badge {
    display: none;
}

.pay-policy {
    .grecaptcha-badge {
        visibility: visible;
        display: block;
    }
}

/** Modal con bordes redondeados */
.modal-content {
    border-radius: 10px !important;
}

.infografia {
    text-decoration: none;
    color: #597a8b;
    float: left;
}

.ff-planmed {
    font-family: 'Planmed', sans-serif !important;
}

.cursor-pointer {
    cursor: pointer;
}

.buttomsPDF {
    display: block;
    position: absolute;
    margin-top: auto;
}

.buttonoptionTop {
    box-sizing: border-box;
    height: 25px;
    width: 80px;
    border: 2px solid #007E84;
    border-radius: 20px;
    text-align: center;
    margin-left: 5px;
    cursor: pointer;
    float: left;

    a {
        height: 15px;
        width: 75px;
        color: #007E84;
        font-family: 'SourceSansPro SemiBold', sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        margin-top: 3px;
        vertical-align: middle;
        text-decoration: none;
        float: left;
    }

    a:link,
    a:visited,
    a:active,
    a:hover {
        text-decoration: none;
        color: #007E84;
    }
}

.buttonoptionBottom {
    box-sizing: border-box;
    height: 25px;
    width: 80px;
    border: 2px solid #007E84;
    border-radius: 20px;
    text-align: center;
    margin-left: 5px;
    margin-top: 12px;
    cursor: pointer;
    float: left;

    a {
        height: 15px;
        width: 75px;
        color: #007E84;
        font-family: 'SourceSansPro SemiBold', sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        margin-top: 3px;
        text-decoration: none;
        vertical-align: middle;
        float: left;
    }

    a:link,
    a:visited,
    a:active,
    a:hover {
        text-decoration: none;
        color: #007E84;
    }
}

.background {
    /*background-image: url("assets/img/background/Fondo_desk.png");/**/
    background-size: cover;
}

.gm-style-iw.gm-style-iw-c {
    height: 28px;
    width: 168px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px 0 #CFCFCF;
    padding-top: 5px;
    text-align: center;
}

.gmnoprint {
    display: none;
}

.pointer {
    cursor: pointer;
}

:focus {
    outline: none;
}

.gm-ui-hover-effect {
    display: none !important;
}

.discount-modal {
    .modal-content {
        background: transparent;
        border: 0;
        z-index: 4;
    }
}

.visit-us-carousel {
    .slick-current {
        padding-top: 0px !important;
        transform: scaleX(1.5) scaleY(1.02);
        border-radius: 18px;

        .img-slide {
            height: 200px;
            padding-top: 0 !important;
            border-radius: 18px;
            cursor: grab !important;
        }
    }

    .slick-slide {
        padding-top: 20px;
    }

    .slick-next {
        background-image: url("/assets/icons/visitus/next.png");
        background-color: #ffffff99;
        border-radius: 25px;
        right: 8px;
        padding: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;

        &:before {
            content: none;
        }
    }

    .slick-next:hover {
        @extend .slick-next
    }

    .slick-next:focus {
        @extend .slick-next
    }

    .slick-prev {
        @extend .slick-next;
        background-image: url("/assets/icons/visitus/past.png") !important;
        left: 8px;
        z-index: 1;
    }
}

.slick-dots li.slick-active button:before {
    color: #4F9AAF !important;
    font-size: 13px;
}

.slick-dots li button:before {
    color: rgba(79, 154, 175, 1.2) !important;
    font-size: 13px !important;
}

.slick-dots {
    bottom: -47px !important;
}

strong {
    font-weight: 700 !important;
}

.theme-green .bs-datepicker-head {
    background-color: $ak-blue4;
}

.theme-green .bs-datepicker-body table td span.selected {
    background-color: $ak-blue4;
}

.bs-datepicker-head button {
    text-transform: capitalize;
}

@media (max-width: 768px) {
    .card-body-faqs__content-collapse {
        .mat-expansion-panel-content {
            background: white;
        }
    }
}

.modal-general {
    button {
        color: #004883 !important;

        &:hover {
            color: white !important;
        }
    }
}


.modal {
    &-backdrop {
        background-color: #2b3d43;

        &.show,
        &.fade {
            opacity: 0.9;
        }
    }

    &-dialog {
        max-width: none !important;
        margin: 0 !important;
    }

    &-content {
        color: white;
        background-color: rgba(#2b3d43, 0.76) !important; //AQUI ES DONDE SE TOMAN LAS CONFIGURACIONES DEL MODAL
        border-radius: 0px !important;
        border: none;
        margin-top: 8%;
        padding: 50px 0;
    }

    &__button {
        min-width: 101px;
        height: 36px;
        font-weight: normal !important;
        border: 1px solid white;
        border-radius: 20px;

        transition: all 0.3s ease-in-out;

        &:hover {
            transform: scale(0.95);
            outline: 0;
        }

        &:disabled {
            opacity: 0.29;
        }

        &-accept {
            color: #597a8b;
            font-size: 12px;
            background: white !important;
        }

        &-cancel {
            font-size: 12px;
            color: white;
            background: none;
        }
    }
}


// MODALES 2.0
.modal {
    &__wrapper {
        z-index: 100000;
        position: fixed;
        background-color: rgba(#2b3d43, 0.9);
        width: 100vw;
        height: 100vh;
    }

    &__body {
        color: white;
        background-color: rgba(#2b3d43, 0.76);
        margin: 110px 0 0 0;

        &-title {
            font-family: 'SourceSansPro', sans-serif;
        }

        &-icon {
            font-size: 70px;
            line-height: 0px;
        }
    }
}



.mat-expansion-indicator::after {
    @media(max-width: 768px) {
        display: none !important;
    }
}

// ESTILOS RESPONSIVOS
@media (min-width: map-get($grid-breakpoints, lg)) {
    .modal {
        &__body {
            &-icon-border {
                border-right: 3px solid rgba(white, 0.52);
            }
        }
    }
}

button {
    outline: none !important;
}

button:focus {
    outline: none !important;
}