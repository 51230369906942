@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?86u8v7');
  src:  url('/assets/fonts/icomoon.eot?86u8v7#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.woff2?86u8v7') format('woff2'),
    url('/assets/fonts/icomoon.ttf?86u8v7') format('truetype'),
    url('/assets/fonts/icomoon.woff?86u8v7') format('woff'),
    url('/assets/fonts/icomoon.svg?86u8v7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
font-family: 'icons';
  src:  url('/assets/fonts/icons.eot?uqowr5');
  src:  url('/assets/fonts/icons.eot?uqowr5#iefix') format('embedded-opentype'),
    url('/assets/fonts/icons.ttf?p9rstq') format('truetype'),
    url('/assets/fonts/icons.woff?p9rstq') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-LinkedIn_Cerulean1:before {
  content: "\e9b5";
}

.icon-FB-Group-10:before {
  content: "\e903";
  color: #fff;
}
.icon-Cerrar:before {
  content: "\e902";
  color: #d4d4d4;
}
.icon-Cerrar:hover:before {
  color: #2B3D43;
}
.icon-FB-Group-11:before {
  content: "\e904";
  color: #fff;
}
.icon-FB-Group-12:before {
  content: "\e905";
  color: #fff;
}
.icon-FB-Group-13:before {
  content: "\e906";
  color: #fff;
}
.icon-FB-Group-14:before {
  content: "\e907";
  color: #fff;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ak-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-faq-icon:before {
  content: "\e900";
}
.icon-circle-star .path1:before {
  content: "\e917";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-star .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-circle-heart .path1:before {
  content: "\e919";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-heart .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-circle-lock .path1:before {
  content: "\e91b";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-lock .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-contact-ok:before {
  content: "\e901";
}
.icon-nav-arrow:before {
  content: "\e91d";
}
.icon-youtube:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-instagram:before {
  content: "\e904";
}
.icon-Phone:before {
  content: "\e905";
}
.icon-lugage:before {
  content: "\e906";
}
.icon-location-marker:before {
  content: "\e907";
}
.icon-tag_discount:before {
  content: "\e908";
}
.icon-cursor-hand .path1:before {
  content: "\e909";
  color: rgb(255, 255, 255);
}
.icon-cursor-hand .path2:before {
  content: "\e91e";
  margin-left: -0.982421875em;
  color: rgb(99, 99, 99);
}
.icon-ambulance-2:before {
  content: "\e90a";
}
.icon-call-emergency:before {
  content: "\e90b";
}
.icon-health:before {
  content: "\e90c";
}
.icon-heartbeat:before {
  content: "\e90d";
}
.icon-lungs:before {
  content: "\e90e";
}
.icon-mircroscope:before {
  content: "\e90f";
}
.icon-nurse:before {
  content: "\e910";
}
.icon-shopping-kart:before {
  content: "\e911";
}
.icon-stars:before {
  content: "\e912";
}
.icon-virus:before {
  content: "\e913";
}
.icon-ambulance-footer:before {
  content: "\e914";
}
.icon-circles:before {
  content: "\e915";
}
.icon-logo-planmed:before {
  content: "\e916";
}
/* Separación de iconos para la nueva versión */



// .icon-portfolio:before {
//   content: "\e9ed";
// }
// .icon-ambulance-40:before {
//   content: "\e902";
// }
// .icon-atention:before {
//   content: "\e903";
// }
// .icon-check-up:before {
//   content: "\e904";
// }

// .icon-dna:before {
//   content: "\e9da";
// }
// .icon-hand:before {
//   content: "\e9db";
// }
// .icon-heart:before {
//   content: "\e9dc";
// }
// .icon-phone:before {
//   content: "\e9e6";
// }
// .icon-apple-icon:before {
//   content: "\e998";
// }
// iconos nuevos parte de infografia
.icon-LinkedIn:before {
  content: "\e97a";
}
.icon-apple-icon:before {
  content: "\e998";
  color: #164074;
}
.icon-cookie .path1:before {
  content: "\e902";
  color: rgb(255, 194, 143);
}
.icon-cookie .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(231, 123, 80);
}
.icon-portfolio:before {
  content: "\e9ed";
  color: #164074;
}
.icon-return .path1:before {
  content: "\ea0d";
  color: rgb(4, 33, 69);
}
.icon-return .path2:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ambulance1:before {
  content: "\e904";
}
.icon-atention:before {
  content: "\e96d";
}
.icon-check-up:before {
  content: "\e98a";
}
.icon-dna1:before {
  content: "\e9dd";
}
.icon-hand1:before {
  content: "\e9de";
}
.icon-heart1:before {
  content: "\e9df";
}
.icon-phone1:before {
  content: "\e9e7";
  color: #FFFFFF;
}
.icon-apple-icon1:before {
  content: "\e999";
  color: #164074;
}
// Iconos ya existentes
.icon-covid_test:before {
  content: "\e91f";
  color: #164074;
}
.icon-stethoscope:before {
  content: "\e9a8";
  color: #00008f;
}
.icon-add:before {
  content: "\e920";
  color: #00008f;
}
.icon-ae-footer:before {
  content: "\e921";
  color: #fff;
}
.icon-ae-header .path1:before {
  content: "\e922";
  color: rgb(0, 100, 199);
}
.icon-ae-header .path2:before {
  content: "\e923";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path3:before {
  content: "\e924";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path4:before {
  content: "\e928";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path5:before {
  content: "\e929";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path6:before {
  content: "\e92a";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path7:before {
  content: "\e92b";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path8:before {
  content: "\e92c";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path9:before {
  content: "\e92d";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path10:before {
  content: "\e92e";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-ae-header .path11:before {
  content: "\e92f";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-alert .path1:before {
  content: "\e930";
  color: rgb(230, 165, 71);
}
.icon-alert .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ambulance-footer .path1:before {
  content: "\e932";
  color: rgb(255, 255, 255);
}
.icon-ambulance-footer .path2:before {
  content: "\e933";
  margin-left: -2.380859375em;
  color: rgb(73, 219, 240);
}
.icon-ambulance-footer .path3:before {
  content: "\e934";
  margin-left: -2.380859375em;
  color: rgb(73, 219, 240);
}
.icon-ambulance-footer .path4:before {
  content: "\e935";
  margin-left: -2.380859375em;
  color: rgb(73, 219, 240);
}
.icon-ambulance:before {
  content: "\e936";
  color: #fff;
}
.icon-american-express-white:before {
  content: "\e937";
  color: #fff;
}
.icon-arrow-selection:before {
  content: "\e938";
  color: #5770be;
}
.icon-check:before {
  content: "\e939";
  color: #1094b9;
}
.icon-circle-user .path1:before {
  content: "\e93a";
  color: rgb(255, 255, 255);
  opacity: 0.32;
}
.icon-circle-user .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(206, 230, 255);
  opacity: 0.6972;
}
.icon-circle-user .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-circle-user .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(89, 122, 139);
}
.icon-close-modal .path1:before {
  content: "\e93e";
  color: rgb(255, 255, 255);
}
.icon-close-modal .path2:before {
  content: "\e93f";
  margin-left: -0.9658203125em;
  color: rgb(43, 61, 67);
}
.icon-close:before {
  content: "\e940";
  color: #00008f;
}
.icon-cloud:before {
  content: "\e941";
  color: #fff;
}
.icon-creditcard-check .path1:before {
  content: "\e942";
  color: rgb(255, 255, 255);
}
.icon-creditcard-check .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(88, 195, 35);
}
.icon-creditcard-check .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-creditcard-ok:before {
  content: "\e945";
  color: #164074;
}
.icon-danger:before {
  content: "\e946";
  color: #00008f;
}
.icon-delete:before {
  content: "\e947";
  color: #999;
}
.icon-discount-ok .path1:before {
  content: "\e948";
  color: rgb(255, 255, 255);
}
.icon-discount-ok .path2:before {
  content: "\e949";
  margin-left: -1.111328125em;
  color: rgb(88, 195, 35);
}
.icon-discount-ok .path3:before {
  content: "\e94a";
  margin-left: -1.111328125em;
  color: rgb(255, 255, 255);
}
.icon-document:before {
  content: "\e94b";
  color: #00008f;
}
.icon-edit:before {
  content: "\e94c";
  color: #fff;
}
.icon-email:before {
  content: "\e94d";
  color: #00008f;
}
.icon-facebook:before {
  content: "\e94e";
  color: #f5f5f5;
}
.icon-help:before {
  content: "\e94f";
  color: #fff;
}
.icon-instagram:before {
  content: "\e950";
  color: #f5f5f5;
}
.icon-mastercard_white .path1:before {
  content: "\e951";
  color: rgb(255, 255, 255);
}
.icon-mastercard_white .path2:before {
  content: "\e952";
  margin-left: -1.2705078125em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-mastercard_white .path3:before {
  content: "\e953";
  margin-left: -1.2705078125em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-mastercard-footer .path1:before {
  content: "\e954";
  color: rgb(255, 255, 255);
}
.icon-mastercard-footer .path2:before {
  content: "\e955";
  margin-left: -1.2841796875em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}
.icon-mastercard-footer .path3:before {
  content: "\e956";
  margin-left: -1.2841796875em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}
.icon-mastercard-footer .path4:before {
  content: "\e957";
  margin-left: -1.2841796875em;
  color: rgb(255, 255, 255);
}
.icon-mastercard-header .path1:before {
  content: "\e958";
  color: rgb(0, 0, 0);
}
.icon-mastercard-header .path2:before {
  content: "\e959";
  margin-left: -1.2841796875em;
  color: rgb(250, 48, 40);
}
.icon-mastercard-header .path3:before {
  content: "\e95a";
  margin-left: -1.2841796875em;
  color: rgb(255, 154, 48);
}
.icon-mastercard-header .path4:before {
  content: "\e95b";
  margin-left: -1.2841796875em;
  color: rgb(255, 97, 40);
}
.icon-mercadopago-footer:before {
  content: "\e95c";
  color: #fff;
}
.icon-mercadopago-header .path1:before {
  content: "\e95d";
  color: rgb(35, 55, 126);
}
.icon-mercadopago-header .path2:before {
  content: "\e95e";
  margin-left: -3.857421875em;
  color: rgb(35, 55, 126);
}
.icon-mercadopago-header .path3:before {
  content: "\e95f";
  margin-left: -3.857421875em;
  color: rgb(35, 55, 126);
}
.icon-mercadopago-header .path4:before {
  content: "\e960";
  margin-left: -3.857421875em;
  color: rgb(35, 55, 126);
}
.icon-mercadopago-header .path5:before {
  content: "\e961";
  margin-left: -3.857421875em;
  color: rgb(35, 55, 126);
}
.icon-mercadopago-header .path6:before {
  content: "\e962";
  margin-left: -3.857421875em;
  color: rgb(35, 55, 126);
}
.icon-mercadopago-header .path7:before {
  content: "\e963";
  margin-left: -3.857421875em;
  color: rgb(35, 55, 126);
}
.icon-mercadopago-header .path8:before {
  content: "\e964";
  margin-left: -3.857421875em;
  color: rgb(0, 164, 242);
}
.icon-mercadopago-header .path9:before {
  content: "\e965";
  margin-left: -3.857421875em;
  color: rgb(0, 164, 242);
}
.icon-mercadopago-header .path10:before {
  content: "\e966";
  margin-left: -3.857421875em;
  color: rgb(0, 164, 242);
}
.icon-mercadopago-header .path11:before {
  content: "\e967";
  margin-left: -3.857421875em;
  color: rgb(0, 164, 242);
}
.icon-mercadopago-header .path12:before {
  content: "\e968";
  margin-left: -3.857421875em;
  color: rgb(0, 164, 242);
}
.icon-mercadopago-header .path13:before {
  content: "\e969";
  margin-left: -3.857421875em;
  color: rgb(255, 255, 255);
}
.icon-mercadopago-header .path14:before {
  content: "\e96a";
  margin-left: -3.857421875em;
  color: rgb(255, 255, 255);
}
.icon-mercadopago-header .path15:before {
  content: "\e96b";
  margin-left: -3.857421875em;
  color: rgb(0, 164, 242);
}
.icon-mercadopago-header .path16:before {
  content: "\e96c";
  margin-left: -3.857421875em;
  color: rgb(255, 255, 255);
}
.icon-mercadopago-header .path17:before {
  content: "\e96e";
  margin-left: -3.857421875em;
  color: rgb(35, 55, 126);
}
.icon-money:before {
  content: "\e96f";
  color: #fff;
}
.icon-phone1:before {
  content: "\e994";
  color: #00008f;
}
.icon-recommendation-2:before {
  content: "\e970";
  color: #264d7e;
}
.icon-recommendation-3:before {
  content: "\e971";
  color: #264d7e;
}
.icon-rejection-1:before {
  content: "\e972";
  color: #4f9aaf;
}
.icon-rejection-2:before {
  content: "\e973";
  color: #4f9aaf;
}
.icon-rejection:before {
  content: "\e974";
  color: #4f9aaf;
}
.icon-return .path1:before {
  content: "\e975";
  color: rgb(4, 33, 69);
}
.icon-return .path2:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-tag-discount:before {
  content: "\e977";
  color: #fff;
}
.icon-tarjetas-footer .path1:before {
  content: "\e978";
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path2:before {
  content: "\e979";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path3:before {
  content: "\e97a";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path4:before {
  content: "\e97b";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path5:before {
  content: "\e97c";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path6:before {
  content: "\e97d";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path7:before {
  content: "\e97e";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path8:before {
  content: "\e97f";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path9:before {
  content: "\e980";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path10:before {
  content: "\e981";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path11:before {
  content: "\e982";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path12:before {
  content: "\e983";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path13:before {
  content: "\e984";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path14:before {
  content: "\e985";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path15:before {
  content: "\e986";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path16:before {
  content: "\e987";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path17:before {
  content: "\e988";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path18:before {
  content: "\e989";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path19:before {
  content: "\e98b";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path20:before {
  content: "\e98c";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path21:before {
  content: "\e98d";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path22:before {
  content: "\e98e";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path23:before {
  content: "\e98f";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path24:before {
  content: "\e990";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-footer .path25:before {
  content: "\e991";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}
.icon-tarjetas-footer .path26:before {
  content: "\e992";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}
.icon-tarjetas-footer .path27:before {
  content: "\e993";
  margin-left: -8.015625em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path1:before {
  content: "\e995";
  color: rgb(0, 0, 0);
}
.icon-tarjetas-header .path2:before {
  content: "\e999";
  margin-left: -7.630859375em;
  color: rgb(0, 0, 0);
}
.icon-tarjetas-header .path3:before {
  content: "\e99a";
  margin-left: -7.630859375em;
  color: rgb(0, 100, 199);
}
.icon-tarjetas-header .path4:before {
  content: "\e99b";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path5:before {
  content: "\e99c";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path6:before {
  content: "\e99d";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path7:before {
  content: "\e99e";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path8:before {
  content: "\e99f";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path9:before {
  content: "\e9a0";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path10:before {
  content: "\e9a1";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path11:before {
  content: "\e9a2";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path12:before {
  content: "\e9a3";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path13:before {
  content: "\e9a4";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path14:before {
  content: "\e9a5";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path15:before {
  content: "\e9a6";
  margin-left: -7.630859375em;
  color: rgb(0, 0, 0);
}
.icon-tarjetas-header .path16:before {
  content: "\e9a7";
  margin-left: -7.630859375em;
  color: rgb(250, 48, 40);
}
.icon-tarjetas-header .path17:before {
  content: "\e9a9";
  margin-left: -7.630859375em;
  color: rgb(255, 154, 48);
}
.icon-tarjetas-header .path18:before {
  content: "\e9aa";
  margin-left: -7.630859375em;
  color: rgb(255, 97, 40);
}
.icon-tarjetas-header .path19:before {
  content: "\e9ab";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path20:before {
  content: "\e9ac";
  margin-left: -7.630859375em;
  color: rgb(35, 55, 126);
}
.icon-tarjetas-header .path21:before {
  content: "\e9ad";
  margin-left: -7.630859375em;
  color: rgb(35, 55, 126);
}
.icon-tarjetas-header .path22:before {
  content: "\e9ae";
  margin-left: -7.630859375em;
  color: rgb(35, 55, 126);
}
.icon-tarjetas-header .path23:before {
  content: "\e9af";
  margin-left: -7.630859375em;
  color: rgb(35, 55, 126);
}
.icon-tarjetas-header .path24:before {
  content: "\e9b0";
  margin-left: -7.630859375em;
  color: rgb(35, 55, 126);
}
.icon-tarjetas-header .path25:before {
  content: "\e9b1";
  margin-left: -7.630859375em;
  color: rgb(35, 55, 126);
}
.icon-tarjetas-header .path26:before {
  content: "\e9b2";
  margin-left: -7.630859375em;
  color: rgb(35, 55, 126);
}
.icon-tarjetas-header .path27:before {
  content: "\e9b3";
  margin-left: -7.630859375em;
  color: rgb(0, 164, 242);
}
.icon-tarjetas-header .path28:before {
  content: "\e9b4";
  margin-left: -7.630859375em;
  color: rgb(0, 164, 242);
}
.icon-tarjetas-header .path29:before {
  content: "\e9b5";
  margin-left: -7.630859375em;
  color: rgb(0, 164, 242);
}
.icon-tarjetas-header .path30:before {
  content: "\e9b6";
  margin-left: -7.630859375em;
  color: rgb(0, 164, 242);
}
.icon-tarjetas-header .path31:before {
  content: "\e9b7";
  margin-left: -7.630859375em;
  color: rgb(0, 164, 242);
}
.icon-tarjetas-header .path32:before {
  content: "\e9e7";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path33:before {
  content: "\e9e8";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path34:before {
  content: "\e9e9";
  margin-left: -7.630859375em;
  color: rgb(0, 164, 242);
}
.icon-tarjetas-header .path35:before {
  content: "\e9ea";
  margin-left: -7.630859375em;
  color: rgb(255, 255, 255);
}
.icon-tarjetas-header .path36:before {
  content: "\e9eb";
  margin-left: -7.630859375em;
  color: rgb(35, 55, 126);
}
.icon-tooltip-1:before {
  content: "\e9b8";
  color: #1094b9;
}
.icon-users:before {
  content: "\e9b9";
  color: #fff;
}
.icon-validar .path1:before {
  content: "\e9ba";
  color: rgb(88, 195, 35);
}
.icon-validar .path2:before {
  content: "\e9bb";
  margin-left: -0.9853515625em;
  color: rgb(255, 255, 255);
}
.icon-verify-data:before {
  content: "\e9bc";
  color: #264d7e;
}
.icon-visa-footer:before {
  content: "\e9bd";
  color: #fff;
}
.icon-visa-header:before {
  content: "\e9be";
}
.icon-visa-white:before {
  content: "\e9bf";
  color: #fff;
}
.icon-youtube:before {
  content: "\e9c0";
}
.icon-close-modal1 .path1:before {
  content: "\e9c1";
  color: rgb(255, 255, 255);
}
.icon-close-modal1 .path2:before {
  content: "\e9c2";
  margin-left: -0.9658203125em;
  color: rgb(43, 61, 67);
}
.icon-close1:before {
  content: "\e9c3";
  color: #00008f;
}
.icon-faq-icon:before {
  content: "\e9c4";
}
.icon-circle-star .path1:before {
  content: "\e9c5";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-star .path2:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-circle-heart .path1:before {
  content: "\e9c7";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-heart .path2:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-circle-lock .path1:before {
  content: "\e9c9";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-lock .path2:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-contact-ok:before {
  content: "\e9cb";
}
.icon-nav-arrow:before {
  content: "\e9cc";
}
.icon-youtube1:before {
  content: "\e9cd";
}
.icon-facebook1:before {
  content: "\e9ce";
}
.icon-instagram1:before {
  content: "\e9cf";
}
.icon-Phone:before {
  content: "\e9d0";
}
.icon-lugage:before {
  content: "\e9d1";
}
.icon-location-marker:before {
  content: "\e9d2";
}
.icon-tag_discount:before {
  content: "\e9d3";
}
.icon-cursor-hand .path1:before {
  content: "\e9d4";
  color: rgb(255, 255, 255);
}
.icon-cursor-hand .path2:before {
  content: "\e9d5";
  margin-left: -0.982421875em;
  color: rgb(99, 99, 99);
}
.icon-ambulance-2:before {
  content: "\e9d6";
}
.icon-call-emergency:before {
  content: "\e9d7";
}
.icon-health:before {
  content: "\e9d8";
}
.icon-heartbeat:before {
  content: "\e9d9";
}
.icon-lungs:before {
  content: "\e9dd";
}
.icon-mircroscope:before {
  content: "\e9de";
}
.icon-nurse:before {
  content: "\e9df";
}
.icon-shopping-kart:before {
  content: "\e9e0";
}
.icon-stars:before {
  content: "\e9e1";
}
.icon-virus:before {
  content: "\e9e2";
}
.icon-ambulance-footer1:before {
  content: "\e9e5";
}
.icon-circles:before {
  content: "\e9e3";
}
.icon-logo-planmed:before {
  content: "\e9e4";
}
.icon-close-modal2 .path1:before {
  content: "\e925";
  color: rgb(255, 255, 255);
}
.icon-close-modal2 .path2:before {
  content: "\e926";
  margin-left: -0.9658203125em;
  color: rgb(43, 61, 67);
}
.icon-close2:before {
  content: "\e927";
  color: #00008f;
}
.icon-faq-icon1:before {
  content: "\e900";
}
.icon-circle-star1 .path1:before {
  content: "\e917";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-star1 .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-circle-heart1 .path1:before {
  content: "\e919";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-heart1 .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-circle-lock1 .path1:before {
  content: "\e91b";
  color: rgb(103, 154, 71);
  opacity: 0.8;
}
.icon-circle-lock1 .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-contact-ok1:before {
  content: "\e901";
}
.icon-nav-arrow1:before {
  content: "\e91d";
}
.icon-youtube2:before {
  content: "\e90f";
}
.icon-facebook2:before {
  content: "\e905";
}
.icon-instagram2:before {
  content: "\e906";
}
.icon-Phone1:before {
  content: "\e907";
}
.icon-lugage1:before {
  content: "\e908";
}
.icon-location-marker1:before {
  content: "\e909";
}
.icon-tag_discount1:before {
  content: "\e90a";
}
.icon-cursor-hand1 .path1:before {
  content: "\e90b";
  color: rgb(255, 255, 255);
}
.icon-cursor-hand1 .path2:before {
  content: "\e9ec";
  margin-left: -0.982421875em;
  color: rgb(99, 99, 99);
}
.icon-ambulance-21:before {
  content: "\e90c";
}
.icon-call-emergency1:before {
  content: "\e90d";
}
.icon-health1:before {
  content: "\e90e";
}
.icon-heartbeat1:before {
  content: "\e996";
}
.icon-lungs1:before {
  content: "\e997";
}
.icon-mircroscope1:before {
  content: "\e91e";
}
.icon-nurse1:before {
  content: "\e910";
}
.icon-shopping-kart1:before {
  content: "\e911";
}
.icon-stars1:before {
  content: "\e912";
}
.icon-virus1:before {
  content: "\e913";
}
.icon-ambulance-footer2:before {
  content: "\e914";
}
.icon-circles1:before {
  content: "\e915";
}
.icon-logo-planmed1:before {
  content: "\e916";
}