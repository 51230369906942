@import 'axa-icons-variables';
@font-face {
    font-family: 'home-font';
    src: url('#{$axa-font-path}/home.woff?1uuuo4') format('woff'),
    url('#{$axa-font-path}/home.ttf?1uuuo4') format('truetype'),
    url('#{$axa-font-path}/home.svg?1uuuo4#home-font') format('svg');
    font-weight: normal;
    font-style: normal;
}

.home-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'home-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.axa-magnifier_glass {
        color: #fff !important;
    }
}

.icon-beneficiary:before {
    content: "\e9e9";
    color: #164074;
  }
  